import * as React from "react";
import Layout from "../components/layout";
import "../main.scss";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "./tabs.scss";
import Accordion from "../components/accordion";
import Coaching from "../files/Coaching-Conference-Request-2018.pdf";
import Ethics from "../files/Peace-Advocate-Code-of-Ethics.pdf";

const PeacePage = () => {
  return (
    <Layout>
      <header id="peace">Find Peace</header>
      <Tabs>
        <TabList>
          <Tab>Pathways to Peace</Tab>
          <Tab>Get Help From A Peace Advocate</Tab>
          <Tab>Request A Coaching Conference</Tab>
        </TabList>

        <TabPanel>
          <p>
            <strong>
              We have organized the biblical truths you need to know to pursue
              peace God's way into several online learning experiences that we
              call pathways. Choose the pathway below that best fits your
              current circumstance or need. You will find practical,
              biblically-based help on how to address your situation. All
              pathways are self-paced, so you can take as much time as you need
              to complete them. An approximate completion time for each pathway
              is shown beside the title.
            </strong>
          </p>
          <hr></hr>
          <br></br>
          <h4>Peace with God – (15 minutes)</h4>
          <br></br>
          <p>
            <em>
              Peace with God makes life worth living. Only He can provide
              lasting security and satisfaction. Until you make peace with God,
              you cannot live a full and meaningful life here on earth or live
              forever with Him after death in heaven. He loves you and wants you
              to have all He has promised. Follow this pathway and find genuine
              peace with Him. Don't wait!
            </em>
          </p>
          <br></br>
          <a
            href="https://peace-point.github.io/peace-with-god/"
            target="_blank"
            rel="noreferrer"
            class="main-button button"
          >
            Get Started
          </a>
          <br></br>
          <br></br>
          <br></br>
          <hr></hr>
          <br></br>
          <h4>Finding Peace with Others – (20 minutes)</h4>
          <br></br>
          <p>
            <em>
              Are you involved in a conflict with a family member, a coworker or
              someone else right now? You can expect tension, turmoil, stress
              and suffering until it is resolved. Your broken relationship will
              not get better on its own. God has provided all you need to get
              through that conflict and find peace. Follow this pathway to learn
              how to apply His principles in your situation and pursue peace
              with others!
            </em>
          </p>
          <br></br>
          <a
            href="https://peace-point.github.io/peace-with-others/"
            target="_blank"
            rel="noreferrer"
            class="main-button button"
          >
            Get Started
          </a>
          <br></br>
          <br></br>
          <br></br>
          <hr></hr>
          <br></br>
          <h4>Helping Others Find Peace – (25 minutes)</h4>
          <br></br>
          <p>
            <em>
              Are people around you struggling with conflicts or disputes? Do
              you care about them enough to help? You may be the peacemaker that
              God has placed in their lives to love and encourage them. With
              prayer and some preparation, you can help them pursue
              reconciliation and resolution and find the peace they seek. Follow
              this pathway, and we'll show you how to guide them!
            </em>
          </p>
          <br></br>
          <a
            href="https://peace-point.github.io/helping-others-find-peace/"
            target="_blank"
            rel="noreferrer"
            class="main-button button"
          >
            Get Started
          </a>
          <br></br>
          <br></br>
          <br></br>
          <hr></hr>
          <br></br>
          <h4>Navigating the Peace Map – (25 minutes)</h4>
          <br></br>
          <p>
            <em>
              Are you unsure about what you need to do to get beyond a current
              conflict or dispute and make things right? Those who successfully
              find their way through conflict to peace and unity follow very
              similar pathways to get there. "The Peace Map" will help you see
              and follow those pathways and recognize the biblical milestones
              you will pass as you go. Follow this pathway to explore the Peace
              Map and stay on track!
            </em>
          </p>
          <br></br>
          <a
            href="https://peace-point.github.io/pathway-to-peace/"
            target="_blank"
            rel="noreferrer"
            class="main-button button"
          >
            Get Started
          </a>
          <br></br>
          <br></br>
          <br></br>
          <hr></hr>
          <br></br>
          <h4>Need more help?</h4>
          <br></br>
          <p>
            If you believe you need personal and professional assistance after
            exploring one or more of these pathways, you may want to{" "}
            <em>
              <strong>Request A Coaching Conference</strong>
            </em>
            . If you want to identify someone you can contact directly to
            discuss your situation, we encourage you to{" "}
            <em>
              <strong>Get Help From A Peace Advocate</strong>
            </em>
            .
          </p>
        </TabPanel>
        <TabPanel>
          <h3>PeacePoint Peace Advocates</h3>
          <p>
            <strong>
              Peace Advocates are men and women who have received special
              training and are prepared to serve Christians and churches facing
              conflicts and disputes as coaches, mediators and arbitrators. All
              Peace Advocates are required to conduct themselves according to
              the{" "}
              <a href={Ethics} target="_blank" rel="noreferrer">
                Peace Advocate Code of Ethics
              </a>
              .
            </strong>
          </p>
          <p>
            These Peace Advocates are PeacePoint staff members who are available
            to help you. Click any name to open a profile that contains
            background information as well as the email addresses and phone
            numbers you can use to make contact.
          </p>
          <Accordion
            title="Tim Sellers"
            content="
          <div>
          <h2>About Tim Sellers</h2>
          <h3>Experience</h3>
          <p>Tim is the President of PeacePoint. He has extensive experience with mediation and arbitration of complex cases involving family, business, employment, construction and organizational conflicts and disputes. He also teaches Biblical peacemaking principles and trains others to serve as peace advocates in their churches and communities.</p>
          <h3>Background</h3>
          <p>Tim is licensed to practice law in both North and South Carolina. He has been active in the practice of business, construction and real estate law since 1980 and was a civil litigator for 26 years. Tim chaired the North Carolina Bar Association’s legislative drafting committee for the North Carolina Planned Community Act. He has enjoyed an “AV” Peer Review Rating from Martindale-Hubbell for more than 20 years. In 2010, he joined the North Carolina Legal Elite Hall of Fame, having been identified by his peers in 2009 as the top lawyer in North Carolina in the real estate practice area. Tim was designated by his peers as a “Super Lawyer” in 2019, 2020 and 2021. He served the City of Charlotte from 1993 through 1999, first as a member of its Community Relations Committee, and later as a two-term member of its City Council.</p>
          <h3>Education &amp; Training</h3>
          <p>Tim graduated with honors from the University of North Carolina School of Law in 1980 after earning a Bachelor of Arts degree in Economics at Davidson College in 1977. Tim received his training as a peace advocate at the Institute for Christian Conciliation (“ICC”), a division of Ambassadors of Reconciliation, and was designated a Certified Christian Conciliator™ in 2000.</p>
          <h3>Employment</h3>
          <p>Tim is the founding member of Sellers, Ayers, Dortch and Lyons, P.A., a civil practice law firm located in Charlotte, North Carolina. He currently serves the firm as consulting counsel.</p>
          <h3>Personal</h3>
          <p>Tim and his wife, Charlene, were married in 1978 and currently reside in the North Carolina mountains. They have two grown children both of whom are married to terrific spouses and four delightful grandchildren.</p>
          </div>
          <section>
          <img className='alignnone size-full wp-image-4878' title='tim-sellers' src='https://i.ibb.co/PjsNYmh/tim-sellers.jpg' alt='Tim Sellers' />
          <h4>CONTACT TIM</h4>
          <p>PO Box 833, Blowing Rock, NC 28605</p>
          <p><strong>Phone</strong><br>
          <a href='tel:704-617-5959'>704-617-5959</a></p>
          <p><strong>Email</strong><br><a href='/contact' target='_blank' rel='noreferrer'>Send Tim a message</a><br>
          </section>
          "
          />
          <Accordion
            title="Robert Whitlow"
            content="
          <div>
          <h2>About Robert Whitlow</h2>
          <h3>Experience</h3>
          <p>Robert is the Chairman of the Board of PeacePoint. He has been interested in peacemaking throughout his legal career that started in Georgia in 1979 and continues at the present in Charlotte, NC. He is a partner in the law firm of Sellers, Ayers, Dortch & Lyons, P.A.</p>
          <h3>Background</h3>
          <p>Robert received his training as a conciliator at the Institute of Christian Conciliation (“lCC”). For over twenty-five years he has focused on dispute resolution and an administrative law practice.</p>
          <h3>Education &amp; Training</h3>
          <p>Robert graduated with honors from the University of Georgia School of Law in 1979 after completing his undergraduate work magna cum laude at Furman University. Licensed to practice law in both Georgia and North Carolina, he has extensive experience in mediation, arbitration, and litigation. He has lectured at numerous legal seminars on topics in the workers compensation and social security disability areas. He has an “AV” Peer Review Rating from Martindale-Hubbell. Robert is the author of over twenty legal suspense novels published by Harper Collins. Four of his novels have become feature films.</p>
          <h3>Employment</h3>
          <p>Robert is a partner in the firm of Sellers, Ayers, Dortch & Lyons, P.A.</p>
          <h3>Personal</h3>
          <p>Robert and Kathy, his wife of over forty years, have four grown children and eleven grandchildren.</p>
          </div>
          <section>
          <img className='alignnone size-full wp-image-4878' title='robert-whitlow' src='https://i.ibb.co/wNsW8bX/robert-whitlow.jpg' alt='Robert Whitlow' />
          <h4>CONTACT ROBERT</h4>
          <p>301 S. McDowell St., Ste. 410 Charlotte, NC 28204</p>
          <p><strong>Phone</strong><br>
          <a href='tel:704-377-5050'>704-377-5050</a></p>
          <p><strong>Email</strong><br><a href='/contact' target='_blank' rel='noreferrer'>Send Robert a message</a><br>
          </section>
          "
          />
          <Accordion
            title="Neil Carmichael"
            content="
          <div>
          <h2>About Neil Carmichael</h2>
          <h3>Experience</h3>
          <p>Experienced as a mediator in a variety of disputes ranging from a multi-party, multimillion-dollar environmental cleanup dispute to intra-church disputes and marital conflicts, Neil believes his role as a Peace Advocate is to help people navigate their way through disputes and conflicts using biblical principles in order to achieve outcomes that edify the individuals involved, strengthen the Christian community, and bring glory to God. He has been involved with PeacePoint since 2009 and volunteers as the ministry’s Director of Training & Technology.</p>
          <h3>Background</h3>
          <p>Neil has had a special interest in Christian dispute resolution and conflict reconciliation for a number of years. This interest led to the focus of his master’s thesis titled “Navigating Congregational Conflict: Achieving Outcomes That Glorify God”. His extensive education and training of mediators and arbitrators includes: (i) training over 1,000 mediators throughout the United States in fundamental and advanced mediation skills, (ii) co-developing and serving as faculty member for a 40-Hour Mediator Skills Training Workshop certified by the North Carolina Administrative Office of the Courts, (iii) co-developing and serving as a faculty member for the American Arbitration Association’s 30-Hour Mediator Skills Workshops, (iv) developing an online, self-paced study course titled “The Fundamentals of Effective Mediation Advocacy,” designed to assist attorneys in acquiring effective mediation advocacy knowledge and skill, (v) writing, producing, and directing, a 35- minute DVD titled Mediation of a Construction Dispute, (vi) developing numerous fundamental and advanced arbitrator training curricula, training manuals, and training DVDs used in the training of over 8,000 arbitrators.</p>
          <p>Neil and his wife Barbara have over ten years experience in marriage ministry conducting Engagement Encounter Weekends and serving as Marriage Mentors to engaged and newlywed couples.</p>
          <h3>Education &amp; Training</h3>
          <p>Neil graduated with honors from the University of North Carolina at Charlotte in 1981 with a B.A. in Political Science and received an M.A. in Religion from Reformed Theological Seminary in 2009. Neil’s dispute resolution and conflict reconciliation training and education include the following: 40-Hour NC Superior Court Mediated Settlement Conference Training (1994); Mediation Training, Peacemaker Ministries (2009); Conflict Coaching, Peacemaker Ministries (2009); Advanced Mediator Training, American Arbitration Association (2009, 2010, 2011)</p>
          <h3>Employment</h3>
          <p>American Arbitration Association (1985 – Present)</p>
          <h3>Personal</h3>
          <p>Neil has been happily married to Barbara since 1975. They have two terrific grown children.</p>
          </div>
          <section>
          <img className='alignnone size-full wp-image-4878' title='neil-carmichael' src='https://i.ibb.co/Wk3FhWX/carmichael.jpg' alt='Neil Carmichael' />
          <h4>CONTACT NEIL</h4>
          <p>8900 Jenna Marie Lane, Charlotte, NC 28210</p>
          <p><strong>Phone</strong><br>
          <a href='tel:704-552-8632'>704-552-8632</a></p>
          <p><strong>Email</strong><br><a href='/contact' target='_blank' rel='noreferrer'>Send Neil a message</a><br>
          </section>
          "
          />
          <Accordion
            title="Mariah McKinney"
            content="
          <div>
          <h2>About Mariah McKinney</h2>
          <h3>Experience</h3>
          <p>Mariah has been a part of PeacePoint since 2013. She received her initial conflict resolution and mediation training through Peacemaker Ministries. Since that time, she has completed additional training and participated in a number of mediations through PeacePoint. She received Criminal District Court mediation and arbitration training while in law school and NC Personnel mediation training as an attorney with the NC Judicial Branch. She now mediates cases for PeacePoint and is a Peace Advocate trainer.</p>
          <h3>Background</h3>
          <p>Mariah’s husband, Anthony (Tony) McKinney is a Pastor in Marion, North Carolina. Tony has also completed Christian mediation and coach training and often assists Mariah as a co-mediator and coach. Mariah enjoys designing customized plans of action to help others navigate through the challenges of life. She offers assistance with goal setting, blended family integration, communication improvement, effective parenting, and customized workplace conflict policies and Church accountability plans.</p>
          <p>Mariah is also a praise and worship leader and singer. Together, Mariah and Tony lead worship and speak, upon request, for special engagements and events.</p>
          <h3>Education &amp; Training</h3>
          <p> Mariah obtained her law degree from North Carolina Central University School of Law in Durham. Prior to law school, she graduated from The University of North Carolina at Wilmington (UNC by the Sea). She has a Bachelor of Arts degree, representing a double major in both Communication Studies and Spanish.</p>
          <h3>Employment</h3>
          <p>Mariah is an attorney and partner of Garlock & McKinney, P.C. She concentrates her practice in the areas of estate administration and probate law, incompetency and guardianship law, estate planning, trust administration, and trustee representation.</p>
          <h3>Personal</h3>
          <p>She is happily married to her husband, Anthony “Tony” McKinney. They enjoy spending quality time with her two stepsons, other family and friends, as well as with their cat, Tang! Tony and Mariah primarily reside in Black Mountain, North Carolina. They also spend several months each year in the North Topsail Beach area with Mariah’s family.</p>
          </div>
          <section>
          <img className='alignnone size-full wp-image-4878' title='mariah-mckinney' src='https://i.ibb.co/tZ0CtBs/mariah-mckinney.png' alt='Mariah McKinney' />
          <h4>CONTACT MARIAH</h4>
          <p>Mariah’s location is in the Marion, Black Mountain, and Asheville areas.</p>
          <p>PeacePoint mailing address:<br>301 S. McDowell St., Ste. 412, Charlotte, NC 28204</p>
          <p><strong>Phone</strong><br>
          <a href='tel:919-749-2350'>919-749-2350</a></p>
          <p><strong>Email</strong><br><a href='/contact' target='_blank' rel='noreferrer'>Send Mariah a message</a><br>
          </section>
          "
          />
        </TabPanel>
        <TabPanel>
          <p>
            <strong>
              We invite you to request a Conflict Coaching Conference if you
              would like to talk to a Peace Advocate about a specific situation.
              These coaching conferences are provided by Peace Advocates at no
              charge. To get started, just complete and send us a Conflict
              Coaching Conference Request. You can find request forms below. The
              more information you provide, the better prepared we can assist
              you.
            </strong>
          </p>

          <p>
            Once we receive your completed Conflict Coaching Conference Request,
            we will assign a Peace Advocate from our staff. Your assigned Peace
            Advocate will contact you by email to schedule a time for you to
            make contact by telephone, Skype or other mutual acceptable means.
            Coaching Conferences are scheduled for thirty minutes. If you still
            need help after your initial conference, you may discuss that with
            your Peace Advocate or contact us about other PeacePoint resources
            and services. PeacePoint reserves the right to decline any Conflict
            Coaching Conference Request received for any reason.
          </p>
          <br></br>
          <a
            href={Coaching}
            target="_blank"
            rel="noreferrer"
            class="main-button button"
            download
          >
            Coaching Conference Request
          </a>
          <br></br>
          <br></br>
        </TabPanel>
      </Tabs>
    </Layout>
  );
};

export default PeacePage;
